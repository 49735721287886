<script setup>
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';

import Layout from '@/components/common/Layout.vue';
import Spinner from '@/components/common/Spinner.vue';
import LinkButton from '@/components/common/LinkButton.vue';
import stripeAccountsService from '@/services/stripeAccounts.service';
import routesConstants from '@/constants/routes.constants';

import subscriptionSuccessImage from '@/assets/images/subscription_success.svg';

const route = useRoute();
const { t } = useI18n();
const toast = useToast();

const loading = ref(true);

const stripeCode = computed(() => route.query.code);
const companyId = computed(() => route.query.state);

const unwatch = watch(
  [companyId, stripeCode],
  ([companyIdValue, stripeCodeValue]) => {
    if (!companyIdValue || !stripeCodeValue) {
      return;
    }

    const confirmStripeAccountCreation = async (compId, authCode) => {
      try {
        loading.value = true;
        await stripeAccountsService.setConnectedId(compId, authCode);

        unwatch();
      } catch (err) {
        const [error] = err;

        toast.error(t(error));
      } finally {
        loading.value = false;
      }
    };

    confirmStripeAccountCreation(companyIdValue, stripeCodeValue);
  },
  {
    immediate: true,
  },
);
</script>

<template>
<Layout>
  <h1 class="tac">{{ t('pages.onlineShopConfiguration.title') }}</h1>
  <div v-if="loading" :class="$style.loadingContentWrapper">
    <p>{{ t('pages.onlineShopConfiguration.pending') }}</p>
    <Spinner class="mt-6" />
  </div>
  <div v-else :class="$style.successContentWrapper">
    <div :class="$style.illustrationWrapper">
      <img :class="$style.illustration" :src="subscriptionSuccessImage" alt="" />
    </div>
    <p>{{ t('pages.onlineShopConfiguration.success') }}</p>
    <LinkButton small :to="`${routesConstants.CATALOG}?online-shop=${true}`">
      {{ t('pages.onlineShopConfiguration.finishSetupButton') }}
    </LinkButton>
  </div>
</Layout>
</template>

<style lang="scss" module>
@import '@/assets/styles/common/variables';
@import '@/assets/styles/common/mixins';

.loadingContentWrapper,
.successContentWrapper {
  margin-top: 2rem;
  
  display: flex;
  flex-direction: column;
  align-items: center;

  @include spacing-children("vertical", 2rem);
}

.illustrationWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.illustration {
  margin-top: 2rem;

  max-width: 40rem;
  width: 100%;
  height: auto;

  padding: 2rem;
}
</style>
